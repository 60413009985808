$grid: 24;

$breakpoints: (
	xxl: 1800px,
	1600: 1600px,
	xl: 1500px,
	1300: 1300px,
	l: 1200px,
	1000: 1000px,
	m: 900px,
	700: 700px,
	s: 600px,
	xs: 300px
);



@for $columns from 1 through $grid {
	[data-g~=">_#{$columns}"] > * {
		width: (100 / ($grid / $columns)) * 1%
	}
}

@for $columns from 1 through $grid {
	[data-g~="_#{$columns}"] {
		width: (100 / ($grid / $columns)) * 1%
	}
}



@each $prefix, $breakpoint in $breakpoints {
	@for $columns from 1 through $grid {
		[data-g~="@#{$prefix}=>_#{$columns}"] > * {
			@media (max-width: $breakpoint) {
				width: (100 / ($grid / $columns)) * 1%
			}
		}
	}
}

@each $prefix, $breakpoint in $breakpoints {
	@for $columns from 1 through $grid {
		[data-g~="@#{$prefix}=_#{$columns}"] {
			@media (max-width: $breakpoint) {
				width: (100 / ($grid / $columns)) * 1%
			}
		}
	}
}
